<template>
  <label-cmpt
    :showTitle="'文章标签-个人统计'"
    :showRefreshDataCmp="true"
    :taskType="'cLabel'"
    :midType="'user'"
    :labelType="'content'"
  ></label-cmpt>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

// import labelCmpt from "./labelCmpt.vue";

import labelCmpt from "./labelCmptNew.vue";

export default {
  name: "contentLabelUserStats",
  components: {
    labelCmpt,
  },

  data() {
    return {
      selfColumn: [
        // { name: "用户标签", value: "userLabelName" },

        {
          name: "阅读数量",
          value: "count",
        },

        {
          name: "点赞数量",
          value: "like",
        },
        {
          name: "分享数量",
          value: "share",
        },
        {
          name: "评论数量",
          value: "comment",
        },
        { name: "喜好标签", value: "contentLabelName" },
        // {
        //   name: "优质评论",
        //   value: "commentView",
        // },
      ],
    };
  },
  methods: {},

  destroyed() {},
  async created() {},
};
</script>

<style scoped>
</style>
